@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "times-new-roman";
    src: url("/src/fonts/times-new-roman.ttf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "times-new-roman";
    src: url("/src/fonts/times-new-roman-bold.ttf");
    font-weight: 700;
    font-style: medium;
    font-display: swap;
  }
  @media (min-width: 1280px) {
    ::-webkit-scrollbar {
      @apply w-2 bg-[#F1F1F1];
    }

    ::-webkit-scrollbar-thumb {
      @apply bg-[#C1C1C1] hover:bg-[#A8A8A8] active:bg-[#787878];
    }
  }
  /* @font-face {
    font-family: "Nunito";
    src: url("/public/font/Nunito/Nunito-VariableFont_wght.ttf");
    font-display: swap;
  } */
  .page_prodInner .prose span,
  p {
    @apply  text-[.875rem] leading-[1.4];
  }
  main {
    @apply min-h-[calc(100vh-530px)] overflow-hidden;
  }
  body {
    @apply m-auto min-w-[23.4375rem] overflow-x-hidden text-[.925rem] scroll-auto font-medium text-title selection:bg-main selection:text-white xs:text-sm;
  }

  iframe {
    @apply max-h-full max-w-full;
  }

  .w {
    @apply mx-auto w-full max-w-[72.75rem] px-3 lg:max-w-[72.75rem] mini:max-w-[72.75rem];
  }

  .base-container {
    @apply relative mx-auto max-w-[120rem];
  }
  /* form */
  .form-box {
    @apply grid grid-cols-2 gap-6 xs:grid-cols-1;
  }
  .form-box label {
    @apply text-[#c5c5c5];
  }
  .form-box .submit-box {
    @apply flex  h-[3.5rem] w-[12.5rem] items-center justify-center bg-white/30 text-white;
  }
  .form-box .submit-box > span {
    @apply !hidden;
  }
  .form-box .submit-box > input {
    @apply h-full w-full cursor-pointer;
  }
  .form-input,
  .form-textarea {
    @apply mt-2.5 block w-full resize-none !border-border !bg-transparent !px-4 !py-2.5 !ring-0 placeholder:!text-sec-text focus:!border-main xs:!text-sm;
  }
  .form-checkbox {
    @apply !h-5 !w-5 !outline-0 !ring-0 checked:!border-main checked:!bg-main;
  }
  .form-select {
    @apply w-full !border-border !px-4 !py-2.5 !outline-0 !ring-0 focus:!border-main xs:!text-sm;
  }
  /* form */

  .swiper-button-prev,
  .swiper-button-next {
    @apply h-12 w-12 rounded bg-main/80;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    @apply text-base text-white;
  }

  .swiper-pagination {
    @apply static flex flex-wrap items-center justify-center gap-3 md:gap-2;
  }

  .swiper-pagination .swiper-pagination-bullet {
    @apply c-active !m-0 h-3 w-3 rounded-full bg-[#CECECE] opacity-100 transition md:h-2 md:w-2;
  }

  .swiper-pagination .swiper-pagination-bullet-active {
    @apply bg-main;
  }

  .bi {
    @apply inline-block fill-current align-middle !leading-[0];
  }
  .bi:before{
    @apply !font-bold;
  }
}

@layer components {
  header .menu li > a {
    @apply text-sm font-bold text-white;
  }
  header .super_menu img {
    @apply h-5 w-5;
  }
  header .super_menu {
    @apply absolute left-0 top-[100%] -z-10 w-[100vw] -translate-x-[calc((100%-1140px)/2)] bg-white shadow-head;
  }
  header .child .list,
  header .super_menu .list {
    @apply relative border-l px-2.5;
  }
  header .child .list {
    @apply pl-2.5 pr-5;
  }
  header .child ul {
    @apply left-5.5 min-w-[19.75rem] py-7.5;
  }
  header .child .list::before,
  header .super_menu .list::before {
    @apply absolute -left-[1px] top-0 h-15  border-l border-main  content-[''];
  }
  header .super_menu a p,
  header .child a p {
    @apply !normal-case;
  }
  header .menu-ul li > a {
    @apply !text-sm !leading-[50px];
  }
  header .menu-ul .child a {
    @apply !leading-none;
  }
  header.active {
    @apply bg-white shadow-header;
  }
  header.active li > a {
    @apply font-bold text-title;
  }
  header.active .logo img:first-child {
    @apply hidden;
  }
  header.active .logo img:last-child {
    @apply block;
  }
  .flex-average-box {
    @apply flex sm:flex-col [&_>a]:flex-1 [&_>div]:flex-1;
  }
  .img-txt-card {
    @apply [&_img]:obj-cover [&_.imgBox]:overflow-hidden [&_.txt]:px-7.5 [&_.txt]:py-6 [&_h3]:line-clamp-2 [&_h3]:h-[56px] [&_h3]:text-xl [&_h3]:text-title [&_img]:!h-auto [&_p]:line-clamp-3 [&_p]:text-desc;
  }
  .img-txt-card .txt > div:last-child {
    @apply mt-6 border-t-2 border-dashed pt-6;
  }
  .gradual-color {
    @apply bg-gradient-to-b from-gradualTop to-main;
  }
  .flex-center,
  .center-img {
    @apply !flex items-center justify-center;
  }
  .absolute-center {
    @apply absolute left-[50%] top-[50%] z-[2] -translate-x-[50%] -translate-y-[50%];
  }
  .active-opacity-show {
    @apply hidden opacity-0 transition-all [.active_&]:block [.active_&]:opacity-100;
  }
  .active-hight-show {
    @apply hidden h-0 transition-all [.active_&]:block [.active_&]:h-auto;
  }
  .active-opacity-hide {
    @apply opacity-100 transition-all [.active_&]:hidden [.active_&]:opacity-0;
  }
  .hover-tran {
    @apply transition hover:transition;
  }
  .ghover-tran {
    @apply transition group-hover:transition;
  }
  .ghover-tran-all {
    @apply transition-all group-hover:transition-all;
  }
  .svg-white {
    filter: contrast(0) brightness(20);
  }
  .svg-a:hover .svg-white {
    filter: contrast(1) brightness(1);
  }
  /* 组件式 */
  .frontBorder {
    @apply relative;
  }
  .frontBorder::before {
    @apply absolute -top-[1px] left-0 h-[1px] w-30 bg-main content-[''];
  }
  .c-fill_btn {
    /* background:linear-gradient(180deg, #FF9100 0%, #EA6000 100%); */
    @apply c-active mt-10 inline-flex h-12 min-w-[175px] cursor-pointer items-center justify-center bg-main text-white hover:bg-main py-2 px-4 text-sm font-bold;
  }
  .c-flex_btn {
    @apply mt-10 inline-flex  cursor-pointer items-center justify-between gap-x-5 text-white [&_img]:h-[56px] [&_p]:underline [&_p]:hover:text-main;
  }
  .c-footer_title {
    @apply mb-2.5 text-xl font-bold text-title;
  }
  .absolute-bg {
    @apply absolute -z-10 w-full bg-[#f8f8f8];
  }
  .card {
    @apply relative mx-auto max-w-[440px] pb-2;
  }
  .card.active {
    @apply h-auto;
  }
  .card .title {
    @apply mb-2 mt-5 text-xl font-bold text-title;
  }
  .card .desc {
    @apply c-title_desc;
  }
  .card .desc.toggleText {
    @apply line-clamp-6 xs:line-clamp-none;
  }
  .card .desc.toggleText ~ .button {
    @apply flex xs:hidden;
  }
  .card .button {
    @apply absolute -bottom-0 z-10 hidden w-full cursor-pointer items-center gap-x-2 py-3 text-base font-bold uppercase text-main;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.85) 0%,
      rgba(255, 255, 255, 100) 50%
    );
  }
  .card.active .desc.toggleText {
    @apply line-clamp-none pb-10;
  }
  .card .button:hover i {
    @apply animate-bounce;
  }
  .c-title_box {
    @apply text-center;
  }
  .c-title_span {
    @apply mb-1.5 block text-xl font-medium text-main;
  }
  .c-title_main {
    @apply mb-1.5 text-[1.625rem] font-bold leading-[35px] text-title;
  }
  .c-title_main2 {
    @apply mb-2.5 text-[1.625rem] font-bold leading-[35px] text-title;
  }
  .c-title_desc {
    @apply space-y-2.5 text-justify text-base font-medium leading-[1.4] text-desc sm:text-left;
  }
  .c-title_desc p {
    @apply space-y-2.5 text-justify text-base font-medium leading-[1.4] sm:text-left;
  }
  img ~ .mark {
    @apply h-full;
  }
  .mark {
    @apply obj-cover absolute left-0 top-0;
  }
  .c-btn-hollow {
    @apply c-active inline-flex items-center gap-x-2.5 whitespace-nowrap border-2 border-main px-[18px] py-[8px] text-base font-bold text-main;
  }
  .c-btn-hollow i::before {
    @apply !text-base !font-semibold;
  }
  .c-btn-hollow p {
    @apply !text-sm;
  }
  .c-btn-hollow p,
  .inline-flex.items-center p.text-main.font-medium,
  .inline-flex.items-center p.text-main.font-bold {
    @apply !capitalize;
  }
  /* .inline-flex.items-center p{
    @apply !capitalize
  } */
  .c-btn {
    @apply c-active inline-block min-w-[11.25rem] cursor-pointer border border-main bg-main px-8 py-3.5 text-center text-white hover:border-title hover:bg-title md:min-w-0 md:px-6;
    /*   */
  }

  i.dot {
    @apply mr-2.5 inline-block aspect-square w-2.5 rounded-full bg-desc;
  }
  .home-tab-btn {
    @apply c-btn w-full max-w-[13.125rem] border-border bg-[#F9FCFE] p-3.5 text-title transition-none hover:border-main hover:bg-[#F9FCFE] hover:text-main [.current_&]:border-main [.current_&]:bg-main [.current_&]:text-white;
  }
  .countNumber {
    @apply grid h-[5.875rem] grid-cols-4 items-center justify-between sm:h-auto sm:grid-cols-2 sm:gap-y-15 xs:grid-cols-3 xs:gap-y-5;
  }
  .countNumber .box {
    @apply self-center text-center xs:self-start;
  }
  .countNumber .box:last-child {
    @apply xs:col-span-3;
  }
  .countNumber .num {
    @apply inline-block text-[40px] font-bold leading-[40px] text-main;
  }
  .countNumber .symbol {
    @apply text-2xl font-bold text-main;
  }
  .countNumber .desc {
    @apply text-base text-title;
  }
}

@layer utilities {
  .title-2xl {
    @apply text-5xl font-bold text-title xl:text-[2.5rem] md:text-4xl xs:text-3xl;
  }

  .title-xl {
    @apply text-4xl font-bold text-title md:text-3xl xs:text-2xl;
  }

  .title-lg {
    @apply text-[2rem] font-bold leading-[2.375rem] text-title md:text-2xl xs:text-xl;
  }

  .title-md {
    @apply text-2xl font-bold text-title md:text-xl xs:text-lg;
  }

  .title-sm {
    @apply text-xl font-bold text-title md:text-lg xs:text-base;
  }

  .title-xs {
    @apply text-lg font-bold text-title md:text-base xs:text-sm;
  }

  .c-active {
    @apply transition active:scale-95 active:duration-100;
  }

  .obj-cover {
    @apply w-full object-cover;
  }
  .obj-coverh {
    @apply h-full w-full object-cover;
  }
  .thumb-hover {
    @apply relative bg-white transition group-hover:scale-110;
  }

  .rotate-x-180 {
    transform: rotateX(180deg);
  }
}

table {
  @apply text-base;
}

.doc--active {
  @apply h-screen overflow-hidden;
}

#noty_layout__center {
  @apply w-full max-w-xl;
}

.noty_bar {
  @apply !mx-3;
}

.noty_body {
  @apply text-center text-base;
}
.c-btn-fill {
  @apply inline-block py-1 px-2 text-white bg-main;
}
.home-service-swiper .icon_1 {
  @apply ml-6;
}
.home-service-swiper .icon_2 {
  @apply ml-10;
}
.home-service-swiper .icon_3 {
  @apply -ml-3;
}
.home-service-swiper .icon_4 {
  @apply -ml-[22px];
}
/* pagination */
.c-pagination {
  @apply text-center text-sm;
}

.c-pagination__list {
  @apply flex flex-wrap justify-center gap-3;
}

.c-pagination__item.current .c-pagination__link {
  @apply pointer-events-none border-main bg-main text-white;
}

.c-pagination__link {
  @apply block rounded border bg-white px-3.5 py-2 hover:border-main hover:text-main xs:px-3 xs:py-1.5;
}

/* desktop menu start */
.desktop-menu__list {
  @apply relative flex h-full items-center justify-between whitespace-nowrap;
}

.desktop-menu__item {
  @apply relative flex h-full items-center px-6 xl:px-4;
}

.desktop-menu__item.has-super-menu {
  @apply static;
}

.desktop-menu__item .desktop-super-menu {
  @apply absolute inset-x-0 top-full mx-auto hidden w-full max-w-[91.25rem] animate-fadeInDown bg-white text-title shadow;
}

.desktop-menu__item.has-super-menu:hover .desktop-super-menu {
  @apply block;
}

.desktop-menu__item.has-super-menu .desktop-menu__link {
  @apply pointer-events-none;
}

.desktop-menu__item .desktop-menu__link {
  @apply relative block text-white;
}

.desktop-menu__link::after {
  @apply absolute -bottom-2 left-1/2 h-0.5 w-0 -translate-x-1/2 bg-white transition-all content-[''];
}

.desktop-menu__arrow {
  @apply ml-1.5 text-xs text-white transition;
}

.desktop-menu__item .desktop-menu__list {
  @apply absolute -left-4 top-full hidden h-auto min-w-[15rem] max-w-[18.75rem] animate-fadeInDown bg-white p-2 text-sm text-title shadow;
}

.desktop-menu__item .desktop-menu__list .desktop-menu__list {
  @apply -top-2 left-full animate-fadeInRight;
}

.desktop-menu__item:hover .desktop-menu__link::after {
  @apply w-full;
}

.desktop-menu__item:hover > .desktop-menu__list {
  @apply block;
}

.desktop-menu__item:hover > .desktop-menu__arrow {
  @apply rotate-x-180;
}

.desktop-menu__item .desktop-menu__item {
  @apply block h-auto p-0 hover:bg-main hover:text-white;
}

.desktop-menu__item .desktop-menu__item .desktop-menu__link {
  @apply line-clamp-1 block px-2 leading-10 text-inherit after:hidden;
}
/* desktop menu end */

/* mobile menu */
.mobile-menu__list {
  @apply divide-y overflow-y-auto px-5 text-base font-bold;
}

.mobile-menu__list--sub {
  @apply hidden animate-fadeInDown divide-y border-t pl-2 text-sm;
}

.mobile-menu__item {
  @apply relative;
}

.mobile-menu__item.active > .mobile-menu__list--sub {
  @apply block;
}

.mobile-menu__item.active > .mobile-menu__arrow {
  @apply rotate-x-180;
}

.mobile-menu__arrow {
  @apply absolute right-0 top-0 flex aspect-square w-12 cursor-pointer items-center justify-center text-xs transition;
}

.mobile-menu__link {
  @apply line-clamp-1 block leading-[3rem];
}

/* .home-banner-swiper-pagination .swiper-pagination-bullet {
  @apply h-1 w-12 rounded-none bg-white/50 xs:w-6;
}

.home-banner-swiper-pagination .swiper-pagination-bullet-active {
  @apply bg-white;
} */

.footer-form__input {
  @apply border-none text-title;
}

.footer-form__submit {
  @apply cursor-pointer border-none bg-main;
}

:is(.current-menu-item, .current-menu-parent, .current-menu-ancestor) > a :not {
  @apply text-main;
}

:is(
    .current-menu-item .desktop-menu__link,
    .current-menu-parent .desktop-menu__link,
    .current-menu-ancestor .desktop-menu__link
  ) {
  @apply after:w-full;
}

.footer-menu-icon {
  @apply hidden transition xs:block;
}

.active .footer-menu-icon {
  @apply rotate-x-180;
}

:is(.pop-form__input, .pop-form__select, .pop-form__textarea) {
  @apply !py-3.5 !text-base xs:!text-sm;
}

.pop-form__textarea {
  @apply h-[12.5rem];
}
.triggerBFC::after {
  content: " ";
  display: table-cell;
  clear: both;
}
/* footer start */
footer form .input_box input,
footer form .textarea_box textarea {
  @apply !border-none !bg-white focus:!border-none;
}
footer form .textarea_box textarea {
  @apply h-[70px];
}
footer form .submit-box input:is([type="submit"]) {
  @apply h-[54px];
}
footer form .submit-box input {
  /* background: linear-gradient(180deg, #FF9100 0%, #EA6000 100%); */
  @apply c-btn mt-2 h-[40px] w-full text-white;
}
/* footer end */
/* page of home */
/* page of home */

.asideMenu h2 {
  @apply relative border-b border-[#eee] text-[1.75rem] font-bold leading-[5rem] text-desc;
}
.asideMenu h2::after {
  @apply absolute -bottom-px left-0 h-0.5 w-20 bg-main content-[''];
}
.asideMenu p:not(.blog p) {
  @apply flex cursor-pointer items-center justify-between text-base hover:text-main;
}
.asideMenu .list a {
  @apply block text-xl hover:text-main;
}
/* .asideMenu .drop_menu .drop_down_icon.active ~ div.drop_down_box p {
  @apply border-b border-text
} */
.asideMenu .drop_menu {
  @apply flex justify-end gap-x-10 sm:mt-5 sm:flex-col sm:gap-5;
}
.asideMenu .drop_menu .drop_down_icon.active ~ div.drop_down_box {
  @apply block;
}
.asideMenu .drop_menu .drop_down_icon.active {
  @apply border-main text-main;
}
.asideMenu .drop_menu .drop_down_box > div.active p {
  @apply border-b border-[#eee];
}
.asideMenu .drop_menu .drop_down_box > div.active p i {
  @apply rotate-90 transition-all;
}
.asideMenu .drop_menu .drop_down_box > div.active ul {
  @apply block;
}
/* page of article */

/* page of newslist */
/* .productInner-swiper .swiper-pagination, */
.newsList-swiper .swiper-pagination {
  @apply absolute mb-10 h-5;
}

.newsList-swiper .swiper-pagination .swiper-pagination-bullet {
  @apply border-[3px] border-white ring-0 sm:border-2;
}
.home-banner-swiper .swiper-pagination-bullet {
  @apply relative bg-transparent;
}
.home-banner-swiper-pagination .swiper-pagination-bullet::after {
  @apply absolute-center absolute z-[3] h-1.5 w-1.5 rounded-full bg-white content-[''];
}
.home-banner-swiper-pagination .swiper-pagination-bullet-active::after {
  @apply bg-main;
}
.home-banner-swiper-pagination .swiper-pagination-bullet-active {
  @apply border-2 border-main bg-transparent p-2 ring-0;
}
/* .productInner-swiper
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active{
    @apply ring-blue-400 ring-2 bg-transparent w-2 h-2 border-red-400 border-4
  } */
.newsList-swiper
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply ring-2 ring-main;
}

/* page of newslist */

.page_home .quick-swiper .swiper-button {
  @apply top-[44px] h-8 w-8 rounded-full md:top-[60%] sm:top-[58%] [&::after]:text-xs [&::after]:font-bold [&::after]:text-main;
}
.page_home .quick-small-swiper::before {
  @apply absolute left-[1%] top-[88px] w-[98%] border-b content-[''];
}
.page_home .quick-swiper .icon {
  @apply flex-center mx-auto h-18 w-18 rounded-full;
}

.swiper-grid-column > .swiper-wrapper {
  flex-flow: column wrap;
}
.swiper-grid > .swiper-wrapper {
  flex-wrap: wrap;
}
.block-icon {
  @apply flex-center ml-1.5 block h-8 w-10 text-white;
}
.block-icon i,
.block-icon img {
  @apply h-4 w-4;
}
.share-social {
  @apply relative flex cursor-pointer items-center justify-center;
}
.page_prodFinder table td {
  @apply py-2.5;
}
.page_prodFinder table th{
  @apply py-1;
}
.page_prodFinder table tr td div:last-child {
  @apply flex items-center justify-between pr-5;
}
.page_prodFinder select:disabled {
  @apply !bg-[#f5f5f5];
}
.page_prodFinder .form-input {
  @apply h-[38px] !py-0;
}
.page_prodFinder table tbody td {
  @apply text-title;
  overflow-wrap: break-word;
}
.page_prodFinder table th,
.page_prodFinder table td {
  @apply pl-5 text-left !font-medium;
}
.page_prodFinder table thead {
  @apply bg-main text-white;
}
.page_prodFinder table thead th:first {
  @apply ml-0;
}
.page_prodFinder table tbody tr:nth-child(2n) {
  @apply border-y !bg-[#f8f8f8];
}
.page_prodInner .prose ul {
  @apply list-none;
}
.page_prodInner table td {
  @apply text-title;
}
.page_prodInner .innerTable {
  @apply border-b-4 border-main bg-[#f8f8f8];
}
.page_prodInner table tr {
  @apply border-b last:border-0;
}
.page_prodInner table tr th,
.page_prodInner table tr td:first-child {
  @apply box-content w-[160px] pl-0 text-left sm:pl-3;
}
.page_prodInner .prose table tr td:first-child {
  @apply w-[240px] xs:w-[50%];
}
.page_prodInner table tr td {
  @apply text-left;
}
.page_prodInner table tr td:last-child {
  @apply w-auto;
}
.page_prodInner .applications .content > .flex > img {
  @apply mt-1 self-start;
}
.page_prodInner .innerTable table {
  @apply w-full xs:pl-3;
}
.page_prodInner .prose table {
  @apply mb-10;
}
.page_prodInner table tr th {
  @apply py-2.5 text-left align-top;
}
.page_prodInner table tr td {
  @apply pl-5 align-top;
}
.page_prodInner .txt .title {
  @apply my-2.5 text-xl font-bold;
}
.page_prodInner .txt .desc {
  @apply font-medium text-desc;
}
.page_prodInner .txt .desc table tr:nth-child(2n) td {
  background: #f8f8f8;
}
.page_prodInner .txt .desc table tr:nth-child(2n) td:first-child {
  background: white;
}
.page_prodInner .txt .desc table tr td {
  border-right: 1px solid #dedede;
}
.page_prodInner .txt .desc table tr td:first-child {
  background: white;
}
.page_prodInner .txt .desc table tr td:last-child {
  border-right: none;
}
.page_prodInner .txt .desc p {
  @apply my-3;
}
.img-box {
  @apply overflow-hidden;
}
.corporate .grid .img-box {
  @apply center-img mx-auto mb-2 h-[88px] max-w-[116px] bg-contain bg-center bg-no-repeat xs:w-full;
}
.corporate .grid .img-box img {
  @apply w-[58px] lg:w-[50px];
}
.corporate .grid .content {
  @apply cursor-pointer;
}
.corporate .grid .content p {
  @apply mx-auto max-w-[116px] text-center font-medium text-title xs:w-full;
}
.corporate .grid .content.active p {
  @apply text-center text-main;
}
.page_manufacturing .strength .content {
  @apply relative;
}
.page_manufacturing .strength .content:nth-child(2n + 1) .box::before {
  @apply absolute right-0 top-[50%] h-[calc(100%-80px)] w-[1px] -translate-y-[50%] bg-[#DEDEDE] content-[''] sm:content-none;
}
.page_manufacturing .strength .content::before {
  @apply absolute bottom-0 h-[1px] w-[calc(100%-40px)] bg-[#DEDEDE] content-[''] sm:content-none;
}
.page_manufacturing .strength .content:nth-child(5)::before,
.page_manufacturing .strength .content:nth-child(6)::before {
  @apply h-0;
}
.page_manufacturing .strength .content::after {
  @apply absolute bottom-0  bg-[#DEDEDE] content-[''];
}
.page_manufacturing .strength .content:nth-child(2n)::before {
  @apply right-0;
}
.page_manufacturing .strength .content:nth-child(2n + 1) .box {
  @apply pr-20 sm:px-3;
}
.page_manufacturing .strength .content:nth-child(2n) .box {
  @apply pl-20 sm:px-3 sm:py-5;
}
.page_manufacturing .strength .content .box {
  @apply box-content py-12 sm:px-3 sm:py-5;
}
.page_manufacturing .strength .content:nth-child(1) .box,
.page_manufacturing .strength .content:nth-child(2) .box {
  /* @apply !pt-0 sm:!pt-5 */
}
.page_manufacturing .strength .content:nth-child(5) .box,
.page_manufacturing .strength .content:nth-child(6) .box {
  @apply !pb-5 sm:!pb-5;
}
.page_about .about-focus-swiper .box ul li {
  @apply relative pl-3;
}
.page_about .about-focus-swiper .box ul li::before {
  @apply absolute left-0 top-[50%] h-1.5 w-1.5 -translate-y-[50%] rounded-full bg-white content-[''];
}
.blog-header-swiper h3 {
  @apply leading-[1.75rem];
}
page_prodInner .prose *,
.blog-header-swiper *,
.page_blogDetail ul li a.c-title_desc {
  @apply text-left;
}
.page_blogDetail .prose * {
  @apply leading-[1.4];
}
.page_blogDetail .prose h2 {
  @apply mb-4;
}
.page_blogDetail .prose p {
  @apply my-2.5;
}
.page_blogDetail form,
.page_contact form {
  @apply !mb-0;
}
.page_home .hexagon img {
  @apply h-5 w-5;
}
.page_blogDetail form input:is([type="text"]) {
  @apply !bg-[#f9f8f5] !py-[7px];
}
.page_blogDetail form .form-input,
.page_blogDetail form .form-textarea {
  @apply !bg-[#f9f8f5] !py-[7px];
}
.about-corporate-swiper .swiper-slide {
  /* pointer-events:auto; */
}
.about-corporate-swiper .swiper-slide.swiper-slide-visible.swiper-slide-active {
  @apply z-20;
}
.finder-form form {
  @apply grid grid-cols-2 gap-x-10 gap-y-3 sm:gap-x-5;
}
.page_contact .info .txt {
  /* @apply max-h-[19.875rem] overflow-y-auto sm:max-h-none */
}
.page_contact .info .txt {
  @apply relative;
}
.page_contact .info .txt::before {
  @apply absolute left-7.5 top-0 z-[-2] h-[16.25rem] w-0 border-l border-main content-[''];
}
.blog-header-swiper-pagination .swiper-pagination-bullet {
  @apply !mx-1 h-2.5 w-2.5;
}

.blog-header-swiper-pagination .swiper-pagination-bullet-active {
  @apply bg-main;
}
.quick-big-swiper .swiper-wrapper {
  @apply !h-full;
}
.swiper-wrapper {
  @apply !h-auto;
}

.map-box iframe {
  @apply !h-full;
}
.agg-workshop-swiper-button-next::after {
  @apply title-md !text-white;
}

#ajaxsearchlite1 form input {
  @apply !h-[38px] !py-0;
}
#ajaxsearchlite1 form button {
  @apply !h-[38px] !w-[38px];
}
.page_souring .prose *,
.prose * {
  @apply leading-[1.4];
}
.prose p {
  @apply mt-2.5;
  /* mb-1.5 mt-0 */
}
.prose p:first-child {
  @apply mb-2.5 mt-0;
  /* mb-1.5 mt-0 */
}
.page_souring figure {
  /* @apply mb-6 */
}

.content1 .part1 li,
.prose ul li,
.wp-block-columns ul li {
  @apply pl-0;
}

.wp-container-core-columns-layout-1 .wp-block-column p:first-child,
.wp-container-core-columns-layout-2 .wp-block-column p:first-child {
  @apply mb-1.5;
}
.wp-block-image figure img {
  @apply obj-cover w-full;
}
.wp-container-core-columns-layout-6 ul {
  @apply space-y-1.5;
}
.wp-container-core-columns-layout-6 .wp-block-column {
  @apply !flex-grow !basis-auto;
}
.wp-block-columns {
  @apply mb-2.5;
}
.page_souring .prose p[style*="font-size:26px"] {
  @apply mb-1.5;
}
header .child ul.shadow-menuUl {
  @apply !whitespace-nowrap;
}
header .bi.bi-list {
  @apply pr-3;
}
span.ajax-loader {
  @apply !hidden;
}
form .grid > p,
form > p {
  @apply !hidden;
}
.page_contact .form-input,
.page_contact .form-textarea,
#pop-form .form-input,
#pop-form .form-textarea {
  @apply mt-0;
}
.home-banner-swiper p.c-title_desc,
.page_industry .grid .c-title_desc,
div.relative > div.relative ~ div.w > div.absolute .c-title_desc,
div.relative > div.relative ~ div.w > div.absolute .c-title_desc p {
  @apply text-left;
}
.page_aggregate {
  @apply h-full;
}
.my-3\.75 h2,
.mytable h2 {
  @apply pl-5 font-semibold;
}
.bi.bi-arrow-right::before {
  content: "\f285" !important;
  @apply !text-sm !font-semibold;
}
div.inline-flex.items-center > p {
  @apply !capitalize;
}
.markImg {
  @apply bg-white;
}
.markBox {
  @apply relative;
}

.markBox::after {
  @apply absolute left-[50%] top-0 -z-10 h-full w-[1px] -translate-x-[50%] bg-[#07417f] content-[''];
}
.date.flex > .inline-flex.items-center,
a.inline-flex.gap-x-2\.5.items-center,
a div.inline-flex.items-center {
  @apply !gap-x-0;
}
.my-12\.5.corporate {
  @apply !my-15 mini:!my-10;
}

.page_contact form textarea {
  @apply !h-[14.625rem] sm:!h-25;
}
header .menu-item > a {
  @apply !text-sm;
}
.prose ul,
.page_souring2 ul {
  @apply pl-[1.125rem];
}
.wp-block-columns {
  @apply !my-0;
}
.prose p {
  @apply mt-2.5;
}
.content1 .part1 ul,
.page_souring .wp-block-columns ul,
.prose ul,
.page_souring2 ul {
  /*  .prose ul li,.content1 .part1 li,.page_souring2 ul li,
.page_souring .wp-block-columns li  */
  @apply my-0;
}
.prose ul li:first-child {
  @apply mt-0;
}
.prose ul li:last-child {
  @apply mb-0;
}
.wp-block-heading {
  @apply mb-2.5;
}
.wp-block-columns.is-layout-flex {
  @apply sm:gap-y-2.5;
}
ul.max-h-\[132px\].mb-2\.5 {
  @apply h-[8.25rem] xs:h-auto;
}
.page_souring2 .forum-swiper p.text-white.text-xl {
  @apply line-clamp-2;
}

.page_blogDetail .title-xs.text-desc p {
  @apply text-xl font-bold;
}
footer .c-btn,
.page_contact .c-btn,
.page_blogDetail .c-btn {
  @apply inline-block min-w-[11.25rem] cursor-pointer border border-main bg-main px-8 py-3.5 text-center text-sm text-white md:min-w-0 md:px-6;
  /* hover:border-title hover:bg-title c-active  */
}

footer ul li.menu-item-type-taxonomy a i {
  @apply mt-2.5 self-start;
}
.about-focus-swiper .beforeIcon {
  @apply relative;
}
.about-focus-swiper .beforeIcon::before {
  @apply absolute left-0 top-[.4375rem] h-1 w-15 bg-main content-[''];
}
.c-btn-hollow.bg-main {
  @apply c-active hover:border-main hover:bg-[#da251c];
}
main.page_prodInner {
  @apply overflow-visible;
}
.page_prodInner .right {
  @apply w-full;
}
.wp-block-button__link.wp-element-button {
  @apply hover-tran rounded-none !border-main !bg-white px-4.5 py-2 text-sm font-bold !text-main hover:!border-main hover:!bg-main hover:!text-white;
}
.total span div {
  @apply inline-block;
}
.page_contact .contact3 {
  @apply !mt-0 pl-[5.25rem];
}
.page_contact .contact3 .title {
  @apply font-bold text-title;
}
.page_contact .contact3 .box2,
.page_contact .contact3 .group1 {
  @apply mt-2;
}
.page_contact .contact3 .box1 .group1 > div,
.page_contact .contact3 .box2 {
  @apply flex gap-x-2.5 sm:flex-col;
}
.page_contact .contact3 a {
  @apply hover:text-main;
}
/* .page_contact .contact3 .box2{
  @apply flex
} */
form input.wpcf7-captchar {
  @apply form-input;
}
.code_box {
  @apply flex items-center;
}
.code_box > div:first {
  @apply shrink-0;
}
.code_box > div {
  @apply flex-1;
}
main.page_blogDetail {
  @apply overflow-visible;
}
.bg-homeContact1 {
  @apply lg:bg-contain;
}
.bg-homeContact2 .bg-homeContact1 .pl-12\.5 {
  @apply pl-[7.125rem] mini:pl-[2.125rem];
}
body .contact {
  @apply hidden;
}

/* en */
body.en .menu .contact {
  @apply flex items-center gap-x-5 sm:hidden;
}
body.en .menu .contact .item span {
  @apply mt-0.5;
}
body.en .menu .contact .item p {
  @apply text-sm;
}
body.en .menu .contact .item {
  @apply flex gap-x-1 hover:text-main;
}
body.en header ul.uppercase > li > a.leading-\[62px\] {
  @apply !text-[15px];
}

footer i.dot {
  @apply mt-2 self-start;
}

/* cn */
body.cn {
  font-family: Arial, Helvetica, sans-serif;
  @apply text-sm leading-[1.3125rem];
}
body.cn div:not(.home-banner-swiper) .c-title_desc,
body.cn .c-title_desc p,
body.cn .quick-small-swiper .hexagon ~ p,
body.cn .page_prodInner .innerTable,
body.cn .page_prodInner .innerTable p,
body.cn .page_prodInner .innerTable th,
body.cn .page_prodInner .innerTable td,
body.cn .prose,
body.cn .aboutContent ul li p,
body.cn .countNumber p.desc,
body.cn .date div.inline-flex > p.text-main,
body.cn .page_prodFinder table th,
body.cn .page_prodFinder table td,
body.cn .page_prodFinder table td p,
body.cn #finder-form span,
body.cn #finder-form p.text-desc,
body.cn div.total span,
body.cn #finder-form .form-input,
body.cn #finder-form .form-textarea,
body.cn .prose ul li,
body.cn .prose p,
body.cn .prose th,
body.cn .prose td,
body.cn .blog-header-swiper .text-desc,
body.cn .blog-header-swiper p,
body.cn .menu-item-object-category a,
body.cn .space-y-3 p,
body.cn .mytable p body.cn .bg-manufacture2 a > p,
body.cn div.w-\[45rem\] h1.c-title_main ~ p.line-clamp-3 {
  @apply text-sm leading-[1.3125rem];
}

body.cn .page_about .advantage {
  @apply !min-h-[33.625rem];
}
body.cn .page_about .w-\[53\.75rem\] {
  @apply !w-[50.75rem];
}
body.cn table td p {
  @apply !text-sm !leading-[1.3125rem];
  line-height: normal;
}
.mytable td p {
  line-height: normal;
  /* @apply !leading-normal  */
}
body.cn footer .menu-item-object-category a,
.home-banner-swiper .c-title_desc {
  @apply !text-xl;
}
#finder-form input,
#finder-form select {
  @apply text-[#6E6E6E];
}
body .vx_box {
  @apply hidden;
}
.sidebar {
  @apply fixed right-0 top-[60%] z-50 -translate-y-1/2 animate-fadeIn xs:bottom-16 xs:right-2.5 xs:top-auto xs:translate-y-0;
}
body.cn header .border-t ul.uppercase {
  @apply pr-[15.5rem];
}
body.cn .whatsapp {
  /* .cn */
  @apply hidden;
}

body.cn .vx_box,
.com_box {
  @apply relative block aspect-square w-16 bg-main hover:bg-title  md:w-14 xs:mb-2.5 xs:w-12;
}
.ad .com_img {
  @apply absolute bottom-0 right-[-50.625rem]  z-[51] h-[14rem] w-[25rem] bg-white  shadow sm:aspect-[570/320] sm:h-auto sm:w-[75vw] xs:right-[-50.625rem];
}
.ad {
  /* @apply mb-4 h-5 xs:mb-2.5; */
}

/* w-[35.625rem] */
/* .test{
  @apply !opacity-0 animate-none 
} */
.ad.showImg .com_img {
  @apply right-0 animate-showToLeft;
}
.sidebar .ad.hideImg .com_img {
  @apply right-[-50.625rem] animate-leaveToRight;
}
.sidebar {
  @apply !animate-none;
}
.sidebar .ad.hideImg.unfirst .com_img {
  @apply animate-none opacity-0;
}

body.cn .vx_box .vx_code {
  @apply absolute right-[110%] top-[50%] z-[52] hidden h-40 w-40 -translate-y-[50%] bg-white p-3 shadow;
}
body.cn .vx_box.active .vx_code {
  @apply block;
}

.close_img {
  @apply flex-center absolute left-0 top-0 z-50;
}

.close_img img,
.close_img span {
  @apply flex-center h-6 w-6 cursor-pointer bg-main text-base text-white;
}
.ad_btn {
  @apply bottom-0 right-0 aspect-square w-14 bg-main hover:bg-title md:w-14 xs:hidden xs:w-12;
}
.ad_btn img {
  @apply aspect-square xs:w-5;
}
.page_industry.page_souring2 .content1 .part1 a.c-btn-hollow {
  @apply no-underline [&_p]:mb-0 [&_p]:text-main [&_p]:hover:text-white;
}
.map-mark {
  @apply absolute left-20 top-10 z-20 space-y-5 sm:space-y-0 sm:px-3;
}
.map-mark .item {
  @apply min-w-[12.5rem] bg-white p-8 text-lg font-bold text-title sm:max-w-[10rem] sm:border-t-2 sm:border-t-main sm:px-0 sm:py-3;
}
/* 中文站百度地图 */
.bdmap {
  @apply relative h-[600px] sm:h-[400px] [&_img]:!max-w-none;
}
.bdmap .mark {
  @apply absolute left-30 top-[50%] z-10 max-w-[12.5rem] translate-y-[-50%] divide-y sm:left-3 sm:top-3 sm:translate-y-0;
}
.bdmap .mark .item {
  @apply flex items-center justify-between bg-white px-5;
}
.bdmap .mark p {
  @apply flex h-10 items-center;
}
.bdmap #allmap {
  @apply map-box absolute left-0 top-0 z-[8] m-0 h-full w-full overflow-hidden;
}
.bdmap .title {
  @apply flex h-12 items-center bg-main pl-5 font-medium text-white hover:underline;
}
.bdmap .title,
.bdmap .item {
  @apply text-base;
}
.bdmap .item .red {
  @apply hidden;
}
.bdmap .item.active {
  @apply text-main;
}
.bdmap .item.active .black {
  @apply hidden;
}
.bdmap .item.active .red {
  @apply block;
}
.bdmap .title,
.bdmap .item {
  @apply cursor-pointer;
}

.\!decoration-transparent {
  @apply no-underline;
}
.innerTable li{
  list-style-type:disc ;
}
.searchPro:hover .pro_name{
    @apply text-main
}